
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
</div>


<form [formGroup]="adForm" class="p-3 d-flex flex-column">
    <mat-form-field>
        <mat-label>Business</mat-label>
        <mat-select formControlName="business" name="business" [compareWith]="compareFn" (selectionChange)="setAdCategory($event)">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let business of businessess" [value]="business">
            <div class="d-flex flex-column">
                {{business.Name}} 
            </div>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="adForm.controls.business.invalid">Business is Required</mat-error>
      </mat-form-field>
    <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput name="title" formControlName="title">
        <mat-error *ngIf="adForm.controls.title.invalid">Title is Required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Body</mat-label>
      <textarea matInput name="body" formControlName="body"></textarea>
      <mat-error *ngIf="adForm.controls.body.invalid">Body is Required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <input matInput [ngxMatColorPicker]="picker" formControlName="adColor">
    <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
    <ngx-mat-color-picker #picker [color]="color"></ngx-mat-color-picker>
    <mat-error *ngIf="adForm.controls.adColor.invalid">Color is Required</mat-error>
</mat-form-field>
    <div class="d-flex justify-content-between">
    <input
    type="file"
    class="file-input"
    (change)="onFile1Selected($event)"
    #fileInput1
    style="display: none"
  />
  <div class="profile-pic-initials lg" (click)="uploadFile1()">
    <img
      class="text-center margin-auto img-fluid"
      src="./../../../assets/user.png"
      #Image1Display
      alt="Image1Display"
    />
  </div>
  <!-- <input
  type="file"
  class="file-input"
  (change)="onFile2Selected($event)"
  #fileInput2
  style="display: none"
/>
<div class="profile-pic-initials lg" (click)="uploadFile2()">
  <img
    class="text-center margin-auto img-fluid"
    src="./../../../assets/user.png"
    #Image2Display
    alt="Image2Display"
  />
</div>    <input
type="file"
class="file-input"
(change)="onFile3Selected($event)"
#fileInput3
style="display: none"
/>
<div class="profile-pic-initials lg" (click)="uploadFile3()">
<img
  class="text-center margin-auto img-fluid"
  src="./../../../assets/user.png"
  #Image3Display
  alt="Image3Display"
/>
</div> -->

</div>
<h3>Preview</h3>
<!-- <app-ad [title]="this.adForm.controls['title'].value" [body]="this.adForm.controls['body'].value" [image1]="this.uploadedImage1" [adColor]="this.adForm.controls['adColor'].value.hex"></app-ad> -->
<div class="p-3" [ngStyle]="{'background-color':'#'+this.adForm.controls['adColor'].value.hex}">
  <ng-container *ngIf="this.uploadedImage1; else placeholder">
    <div class="bm-card d-flex flex-column" [style]="" [ngStyle]="{'background-image':'url'+'('+this.uploadedImage1.source +')'}">
      <div class="bg-50perc-black p-3 m-3">
        <h1 class="text-white">{{this.adForm.controls['title'].value}}</h1>
        <p class="text-white">{{this.adForm.controls['body'].value}}</p>
      </div>
    </div>
  </ng-container>
  
  <ng-template #placeholder>
    <div class="bm-card d-flex flex-column" [style.backgroundImage]="'../assets/imgUpload.png'">
      <div class="bg-50perc-black p-3 m-3">
        <h1 class="text-white">{{this.adForm.controls['title'].value}}</h1>
        <p class="text-white">{{this.adForm.controls['body'].value}}</p>
      </div>
    </div>
  </ng-template>
  </div>
  <button [disabled]="adForm.invalid" class="mt-3" (click)="submit()" mat-raised-button>Save</button>
</form>
