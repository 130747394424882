export enum ParseClasses {
    PATIENTS = 'Students',
    PARENTS = 'Parents',
    BUSINESSES = 'Businesses',
    STUDENTS = 'Students',
    APPOINTMENTS = 'Appointments',
    SERVICES = 'Services',
    LOCATIONS = 'Locations',
    PRACTITIONERS = 'Practitioners',
    UPLOADS = 'Uploads',
    DAILYREPORT = 'DailyReports',
    MEALPLANS = 'MealPlans',
    MEALS = 'Meals',
    GROUPS = 'Groups',
    ACCOUNT = 'Accounts',
    MESSAGES = 'Messages',
    LEADS = 'Leads',
    USER = "_User",
    NOTIFICATIONS = "Notifications",
    ADS = 'Ads',
    TERMS = 'TermsAndConditions',
    PRIVACY = 'Privacypolicy',
    CUSTOMERCONSENT = 'CustomerConsent'
}
