
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
   <mat-stepper linear #stepper
    [orientation]="(stepperOrientation | async)!">
    <mat-step state="done" *ngIf="profileForm" [completed]="profileForm?.valid" [stepControl]="profileForm" label="Profile">
        <mat-card class="m-3">
            <mat-card-content>
                <form class="user" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                    <div class="d-flex row p-3">
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Business name</mat-label>
                          <input matInput placeholder="Name" name="name" formControlName="Name">
                          <mat-error *ngIf="profileForm.controls.Name.invalid">Name is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>About</mat-label>
                        <textarea rows="6" matInput placeholder="About" name="About" formControlName="About"></textarea>
                        <mat-error *ngIf="profileForm.controls.About.invalid">About is Required</mat-error>
                    </mat-form-field>
                     
                    <mat-form-field appearance="outline">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="Category" [compareWith]="compareFn">
                              <mat-option *ngFor="let category of categories;" [value]="category">{{ category.name }}</mat-option>
                          </mat-select>
                        <mat-error *ngIf="profileForm.controls.Category.invalid">Category is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>Address</mat-label>
                      <textarea rows="4" matInput placeholder="Address" formControlName="Address"></textarea>
                      <mat-error *ngIf="profileForm.controls.Address.invalid">Address is Required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Website</mat-label>
                    <input matInput placeholder="URL" formControlName="Website">
                </mat-form-field>
                  </div>
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Mobile</mat-label>
                          <input matInput placeholder="Mobile" formControlName="Mobile">
                          <mat-error *ngIf="profileForm.controls.Mobile.invalid">Mobile is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Email</mat-label>
                          <input matInput placeholder="Email" formControlName="Email">
                          <mat-error *ngIf="profileForm.controls.Email.invalid">Email is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Registration Number</mat-label>
                        <input matInput placeholder="RegistrationNumber" formControlName="RegistrationNumber">
                        <mat-error *ngIf="profileForm.controls.RegistrationNumber.invalid">Registration Number is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>Registration Date</mat-label>
                      <input matInput placeholder="RegistrationDate" formControlName="RegistrationDate">
                      <mat-error *ngIf="profileForm.controls.RegistrationNumber.invalid">Registration Date is Required</mat-error>
                  </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                      <mat-label>Ownership info / Owner Name</mat-label>
                      <input matInput placeholder="OwnershipInfo" formControlName="OwnershipInfo">
                      <mat-error *ngIf="profileForm.controls.OwnershipInfo.invalid">Ownership info is Required</mat-error>
                  </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Owner ID</mat-label>
                        <input matInput placeholder="Partner ID" name="OwnerID" formControlName="OwnerID">
                        <mat-error *ngIf="profileForm.controls.OwnerID.invalid">Owner ID Required</mat-error>
                    </mat-form-field>
                    <mat-form-field  appearance="outline">
                      <mat-label>Status</mat-label>
                        <mat-select formControlName="Status">
                          <mat-option></mat-option>
                            <mat-option *ngFor="let status of statusArray;" [value]="status">{{ status }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12 d-flex flex-column align-items-center">
                    <h2 id="Consent">I accept the <a href="https://advibe.b4a.app/terms-and-conditions" target="_blank">Terms of Service</a>?</h2>
                    <mat-radio-group
                      aria-labelledby="Consent"
                      formControlName="InformedDataConsent"
                    >
                      <mat-radio-button class="example-radio-button m-3" [value]="true">
                        Yes
                      </mat-radio-button>
                      <mat-radio-button class="example-radio-button m-3" [value]="false">
                        No
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error
                      *ngIf="
                        profileForm.controls['InformedDataConsent'].errors ||
                        (!business?.InformedDataConsent &&
                          !profileForm.controls['InformedDataConsent'].value)
                      "
                      >In order for you to use the platform we need your consent.</mat-error
                    >
                  </div>
                </div>
                <mat-toolbar class="d-flex justify-content-end">
                    <button type="submit" [disabled]="!profileForm.valid || saving" mat-raised-button color="primary">
                      {{ profileForm.dirty ? 'Save' : 'Next' }}
                    </button>
                  </mat-toolbar>
                </form>
            </mat-card-content>
          </mat-card>
    </mat-step>
    <mat-step *ngIf="profile" [completed]="profile.Document && profile.Document.name" [stepControl]="thirdFormGroup" label="Consent">
        <app-student-consent-form (emitBack)="back()" [business]="profile" (emitStudent)="saveBusinessConsent($event)"></app-student-consent-form>
        <div class="w-100 d-flex flex-column justify-content-center align items-center" *ngIf="saving">
          <mat-spinner color="accent"></mat-spinner>
          <p >Saving Profile</p>
        </div>
        <!-- <h2>Consent form to be developed</h2> -->
    </mat-step>
  </mat-stepper>