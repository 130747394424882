import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { ParseClasses } from '../../../backend/enum/ParseClasses.enum';
import { ParsePointer } from '../../../backend/interfaces/ParsePointer';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  currentUser;
  showChat = new BehaviorSubject<boolean>(null);
  showChat$ = this.showChat.asObservable();
  chatConversations = new BehaviorSubject<any>(null);
  chatConversations$ = this.chatConversations.asObservable();
  queryMessages = new Parse.Query(ParseClasses.MESSAGES);
  constructor() {
    
   }

  createChatSpace(name: string): Promise<any> {
    return Parse.Cloud.run('createChatSpace', { name });
  }

  createChatMessage(spaceName: string, message: string): Promise<any> {
    return Parse.Cloud.run('createChatMessage', { spaceName, message });
  }

  sendMessage(message: string, recipient: string, conversationId: string): Promise<any> {
    return Parse.Cloud.run('createChatMessage', { message, recipient, conversationId});
  }

  createLead(message: string, recipient: string): Promise<any> {
    return Parse.Cloud.run('createLead', { message, recipient});
  }

  getChats(id: string){
    this.queryMessages.equalTo('Recipient', new ParsePointer(ParseClasses.USER, id));
    this.queryMessages.find().then(res => {
      this.chatConversations.next(_.uniq(res, i => i.get('Sender').id));    
    })
  }

}
