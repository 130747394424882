<form [formGroup]="orderForm" (ngSubmit)="placeOrder()">
  <h1 class="text-info text-center" style="padding: 20px; color: rgb(0, 0, 0) !important; font-family: Raleway">
    Your package
  </h1>

  <div class="row d-flex justify-content-center">
    <!-- <div class="col-md-4 form-check relative" (click)="selectSubscriptionPackage(1)"
      [class.selected-option]="orderForm.controls.package.value === '1000'">
      <h2 class="m-3">Kickstart 101</h2>
      <p>Limited ads</p>
      <p>R1000 / $56</p>
      <p>
        Monthly This will include 10 ads being posted on your selected category.
      </p>
      <fa-icon *ngIf="orderForm.controls.package.value === '1'" class="text-white absoluteRightTop"
        [icon]="faCheckCircle"></fa-icon>
    </div>
    <div class="col-md-4 form-check relative" (click)="selectSubscriptionPackage(2)"
      [class.selected-option]="orderForm.controls.package.value === '2250'">
      <h2 class="m-3">VibeUp 101</h2>
      <p>Unlimited ads</p>
      <p>R2250.00 / $ 125.00</p>
      <p>
        Monthly This will include UNLIMITED ads being posted on your selected
        category.
      </p>
      <fa-icon *ngIf="orderForm.controls.package.value === '1'" class="text-white absoluteRightTop"
        [icon]="faCheckCircle"></fa-icon>
    </div> -->
    <div class="col-md-4 form-check relative text-center selected-option" (click)="selectSubscriptionPackage(0)">
      <h2 class="m-3">Let's go! Early bird special</h2>
      <p class="strike-through text-grey m-0">R2250.00</p>
      <h3 class="mat-title">R149 p.m</h3>
      <p>
        UNLIMITED ads being posted on your selected category.
      </p>
      <fa-icon *ngIf="orderForm.controls.package.value === '0'" class="text-white absoluteRightTop"
        [icon]="faCheckCircle"></fa-icon>
    </div>
  </div>

  <h1 class="text-info text-center" style="padding: 20px; color: rgb(0, 0, 0) !important; font-family: Raleway">
    Account holder
  </h1>
  <div class="mb-3">
    <label for="name" class="form-label">Name</label>
    <input formControlName="name" type="text" class="form-control" id="name" />

    <p *ngIf="
        orderForm.controls.name.errors
          ? orderForm.controls.name.errors['required']
          : ''
      ">
      Required
    </p>
  </div>
  <div class="mb-3">
    <label for="surname" class="form-label">Surname</label>
    <input formControlName="surname" type="text" class="form-control" id="surname" />
  </div>
  <div class="mb-3">
    <label for="email" class="form-label">Email</label>
    <input formControlName="email" type="email" class="form-control" id="email" />
  </div>
  <div class="mb-3">
    <label for="number" class="form-label">Mobile</label>
    <input formControlName="contactNumber" type="string" class="form-control" id="number" />
  </div>
  <div class="mb-3">
    <label for="address" class="form-label">Address</label>
    <input formControlName="address" type="text" class="form-control" id="address" />
  </div>
  <div class="mb-3">
    <label for="billingDate" class="form-label">Billing date</label>
    <input formControlName="billingDate" readonly type="text" class="form-control" id="billingDate" />
  </div>
  <div class="mb-3">
    <mat-radio-group formControlName="cycles">
      <mat-radio-button value="1" class="mr-3">1 month</mat-radio-button>
      <mat-radio-button value="6" class="mr-3">6 months</mat-radio-button>
      <mat-radio-button value="12">12 months</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="row m-3">
    <button mat-raised-button color="primary" [disabled]="orderForm.invalid" type="submit" class="text-white flex-1">
      Submit
    </button>
  </div>
</form>
<form ngNoForm [action]="payfastURL" method="post" *ngIf="user">
  <input type="hidden" name="merchant_id" [ngModel]="payfastObject.merchant_id" />
  <input type="hidden" name="merchant_key" [ngModel]="payfastObject.merchant_key" />
  <input type="hidden" name="return_url" [ngModel]="payfastObject.return_url" />
  <input type="hidden" name="cancel_url" [ngModel]="payfastObject.cancel_url" />
  <input type="hidden" name="notify_url" [ngModel]="payfastObject.notify_url" />
  <input type="hidden" name="name_first" [ngModel]="orderForm.controls.name.value" />
  <input type="hidden" name="name_last" [ngModel]="orderForm.controls.surname.value" />
  <input type="hidden" name="email_address" [ngModel]="orderForm.controls.email.value" />
  <input type="hidden" name="cell_number" [ngModel]="orderForm.controls.contactNumber.value" />
  <input type="hidden" name="m_payment_id" [ngModel]="user.id" />
  <input type="hidden" name="amount" [ngModel]="orderForm.controls.package.value" />
  <input type="hidden" name="item_name" [ngModel]="payfastObject.item_name" />
  <input type="hidden" name="item_description" [ngModel]="payfastObject.item_description" />
  <input type="hidden" name="custom_int1" value="2" />
  <input type="hidden" name="custom_str1" value="Extra order information" />
  <input type="hidden" name="subscription_type" value="1" />
  <input type="hidden" name="billing_date" [ngModel]="payfastObject.billing_date" />
  <input type="hidden" name="recurring_amount" [ngModel]="orderForm.controls.package.value" />
  <input type="hidden" name="frequency" value="3" />
  <input type="hidden" name="cycles" [ngModel]="orderForm.controls.cycles.value" />
  <input type="hidden" name="signature" [ngModel]="payfastSignature" />
  <input type="input" style="visibility: hidden" #submitFormForPayment type="submit" />
</form>
